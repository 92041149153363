import React from 'react';

const TermlyCookieBanner = () => {
  const termlySource = `https://app.termly.io/resource-blocker/${process.env.NEXT_PUBLIC_TERMLY_CONSENT_ID}?autoBlock=on`;

  return (
    <React.Fragment>
      <script type="text/javascript" src={termlySource}></script>
    </React.Fragment>
  );
};

export default TermlyCookieBanner;
