import 'styles/globals.css';

// eslint-disable-next-line import/no-unresolved
import { GoogleTagManager } from '@next/third-parties/google';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import TermlyCookieBanner from 'features/termly/termly-consent-banner';
import { oswald, roboto } from 'lib/font/font-config';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import SEO from '../../next-seo.config';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY || '';
const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST || '';

if (typeof window !== 'undefined') {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const canonicalUrl = (
    `${process.env.NEXT_PUBLIC_SITE_URL}` +
    (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0];

  return (
    <main className={`${roboto.variable} ${oswald.variable}`}>
      <DefaultSeo {...SEO} canonical={canonicalUrl} />
      <PostHogProvider client={posthog}>
        <Component {...pageProps} />
      </PostHogProvider>
      <TermlyCookieBanner />
      <GoogleTagManager gtmId={GTM_ID} />
      <Toaster />
      <Analytics />
    </main>
  );
}
