import type { DefaultSeoProps } from 'next-seo';

const defaultSeoProps: DefaultSeoProps = {
  title: 'EarlyNode',
  description:
    'EarlyNode is a Media Company & Startup Studio providing resources to Bootstrapped and Venture-Backed SaaS Founders to help them start, build and grow their SaaS companies.',
  canonical: 'https://earlynode.com',
  openGraph: {
    url: 'https://earlynode.com/',
    type: 'article',
    title: 'EarlyNode – Building Internet Businesses',
    locale: 'en',
    siteName: 'EarlyNode',
    site_name: 'EarlyNode',
    images: [
      {
        url: 'https://earlynode-media.com/wp-content/uploads/2021/10/EN-Ventures-Branding-1.png',
      },
    ],
    description:
      'We start, operate and grow long-lasting internet businesses. No outside investors - only founders committed to the long haul!',
  },
  twitter: {
    handle: '@earlynode',
    site: '@site',
    cardType: 'summary_large_image',
  },
};

export default defaultSeoProps;
